<template>
  <v-card class="pa-6">
    <v-data-table
      v-if="!loading"
      :headers="headers"
      :items="cidades"
      :items-per-page="10"
      :sort-by="['id']"
      class="data-tables "
      :class="{ 'data-tables__row-click': vueEditCidade }"
      @click:row="openDialog"
      hide-default-footer
    >
    </v-data-table>
    <Carregando v-else />
    <v-dialog v-model="dialog" max-width="350">
      <v-card :loading="atualizando" :disabled="atualizando">
        <v-card-title class="subtitle-1">
          {{ $tc("global.atualizar") }} {{ cidade.nome }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="cidade.nome"
            :label="$tc('global.nome')"
            class="mt-4"
            dense
            hide-details
          ></v-text-field>
          <v-text-field
            v-model="cidade.departamento"
            :label="$tc('global.estado')"
            class="mt-6"
            dense
            hide-details
          ></v-text-field>
          <v-text-field
            v-model="cidade.pais"
            :label="$tc('global.pais')"
            class="mt-6"
            dense
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="anchors" text @click="dialog = false">
            {{ $tc("botoes.cancelar") }}
          </v-btn>
          <v-btn color="anchors" text @click="updateCidade(cidade)">
            {{ $tc("global.atualizar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { fetchCidades, putCidade } from "@/services/api/cidades.api.js";
import { mapGetters } from "vuex";

export default {
  name: "ListaCidades",
  data: () => ({
    loading: true,
    cidades: null,
    cidade: {},
    atualizando: false,
    dialog: false,
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueEditCidade() {
      return this.getAccess("vueCidade", "editar");
    },
    headers() {
      return [
        {
          text: this.$tc("global.nome"),
          value: "nome",
        },
        {
          text: this.$tc("global.estado"),
          value: "departamento",
        },
        {
          text: this.$tc("global.pais"),
          value: "pais",
        },
      ];
    },
  },
  methods: {
    openDialog(cidade) {
      if (this.vueEditCidade) {
        this.dialog = !this.dialog;
        this.cidade = cidade;
      }
    },
    updateCidade(cidade) {
      this.atualizando = true;
      this.$Progress.start();
      putCidade(cidade.id, cidade)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.atualizadosucesso"));
            this.dialog = false;
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.atualizando = false;
        });
    },
    getCidades() {
      this.loading = true;
      this.cidades = null;
      this.$Progress.start();
      fetchCidades()
        .then((response) => {
          this.cidades = response;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {},
  created() {
    this.getCidades();
  },
};
</script>

<style scoped lang="scss"></style>
